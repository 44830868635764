import React from 'react';
import { TextTypography, Text } from 'wix-ui-tpa/cssVars';
import { classes } from './MenuState.st.css';
import { Trans, useEnvironment } from '@wix/yoshi-flow-editor';
import { dataHooks } from 'root/dataHooks';

export const MenuState = ({ content }: { content: string }) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={classes.menuState} data-hook={dataHooks.menus.menuState.container}>
      <Text
        data-hook={dataHooks.menus.menuState.content}
        tagName="h2"
        typography={isMobile ? TextTypography.smallTitle : TextTypography.largeTitle}
      >
        <Trans i18nKey={content} />
      </Text>
    </div>
  );
};
