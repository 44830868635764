import { useMemo, type CSSProperties } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { Preset } from 'root/utils/presets';
import type { ItemProps } from './Item';
import { isSectionChecked, isPriceShowed } from 'root/utils/display';
import type { MenuCheckbox } from 'root/clientTypes';
import { useGetDisplaySettingsParams } from 'root/hooks/useGetDisplaySettingsParams';
import { useItemsContext } from 'root/contexts/ItemsContext';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { Alignment } from 'root/consts';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import type { ItemImage } from 'root/apiTypes';

export const useItemLogic = ({ item }: ItemProps) => {
  const { sectionId } = useItemsContext();
  const {
    showItemDescription,
    showItemPrice,
    showItemZeroPrice,
    showItemCurrency,
    showItemLabels,
    showItemImage,
    showItemDividers,
  } = useGetDisplayStylesParams();
  const { preset, stripItemImageWidth } = useGetLayoutStylesParams();
  const { isRTL } = useEnvironment();
  const { showItemImagePlaceholder, showImagePlaceholderForAllMenus, showZeroPricingForAllMenus } =
    useGetDisplayStylesParams();
  const { checkPricingOptionsMenus, checkImagePlaceholderMenus, imagePlaceholder } = useGetDisplaySettingsParams();
  const gridItemAlignment = useGetAlignment('gridItemAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridItemAlignment,
    [Preset.Columns]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: getAlignmentText(isRTL ? Alignment.Right : Alignment.Left),
  };

  const textAlignCss = { textAlign: alignmentMap[preset as Preset] as CSSProperties['textAlign'] };

  const hasPriceVariants = (item.priceVariants?.variants ?? []).length > 0;

  const priceContent = showItemCurrency
    ? item.priceInfo?.formattedPrice
    : item.priceInfo?.formattedPriceNoCurrency ?? '';

  const priceOptionSectionChecked =
    showZeroPricingForAllMenus || isSectionChecked(checkPricingOptionsMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowPrice = isPriceShowed({
    price: item.priceInfo?.price ?? '',
    showPrice: showItemPrice,
    showZeroPrice: showItemZeroPrice,
    sectionChecked: priceOptionSectionChecked,
  });

  const shouldShowItemDividers = showItemDividers && [Preset.Columns, Preset.Strip].includes(preset as Preset);
  const shouldShowLabels = item.labels && item.labels.length > 0 && showItemLabels;

  const imagePlaceholderSectionChecked =
    showImagePlaceholderForAllMenus || isSectionChecked(checkImagePlaceholderMenus as MenuCheckbox[], sectionId ?? '');

  const shouldShowImagePlaceholder = useMemo(
    () => (showItemImagePlaceholder ? imagePlaceholderSectionChecked : false),
    [imagePlaceholderSectionChecked, showItemImagePlaceholder]
  );
  const shouldShowItemImage = showItemImage && (item?.image || shouldShowImagePlaceholder);
  const itemImage = useMemo(
    () => (item?.image ? item?.image : shouldShowImagePlaceholder ? imagePlaceholder : undefined) as ItemImage,
    [imagePlaceholder, item?.image, shouldShowImagePlaceholder]
  );
  const imageWidth = useMemo(
    () => (preset === Preset.Strip ? (stripItemImageWidth as number) : itemImage?.width),
    [itemImage?.width, preset, stripItemImageWidth]
  );

  return {
    hasPriceVariants,
    priceContent,
    showItemDescription,
    shouldShowPrice,
    shouldShowItemImage,
    textAlignCss,
    shouldShowLabels,
    shouldShowItemDividers,
    shouldShowImagePlaceholder,
    itemImage,
    imageWidth,
  };
};
