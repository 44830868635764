import React from 'react';
import type { ShowcasePopulatedItem } from 'root/apiTypes';
import { Item } from 'root/components/Menus/Item/Item';
import { classes } from './Items.st.css';
import { dataHooks } from 'root/dataHooks';
import { ItemsContextProvider } from 'root/contexts/ItemsContext';

export const Items = ({ items, sectionId }: { items: ShowcasePopulatedItem[]; sectionId: string }) => {
  return (
    <ItemsContextProvider value={{ sectionId }}>
      <div className={classes.itemsContainer} data-hook={dataHooks.items.container}>
        {items.map((item) => (
          <Item key={item.id} item={item} />
        ))}
      </div>
    </ItemsContextProvider>
  );
};
