import type { IStyleParamValuesByType, StyleParamType, createStylesParams } from '@wix/tpa-settings';
import type { IDisplayStylesParams } from './Settings/Tabs/DisplayTab/DisplayStylesParams';
import { DisplayStylesParams } from './Settings/Tabs/DisplayTab/DisplayStylesParams';
import type { IDesignStylesParams } from './Settings/Tabs/DesignTab/DesignStylesParams';
import { DesignStylesParams } from './Settings/Tabs/DesignTab/DesignStylesParams';
import type { ILayoutStylesParams } from './Settings/Tabs/LayoutTab/LayoutStylesParams';
import { LayoutStylesParams } from './Settings/Tabs/LayoutTab/LayoutStylesParams';

export type StylesParamsType<T extends Record<string, StyleParamType>> = ReturnType<typeof createStylesParams<T>>;

export type IStylesParams = IDesignStylesParams & IDisplayStylesParams & ILayoutStylesParams;

export const stylesParams: StylesParamsType<IStylesParams> = {
  ...DisplayStylesParams,
  ...DesignStylesParams,
  ...LayoutStylesParams,
};

export default stylesParams;

export type StyleParamValue = IStyleParamValuesByType[keyof IStyleParamValuesByType];
