import React from 'react';
import { Badge, BadgePriority } from 'wix-ui-tpa/cssVars';
import type { Label as LabelType } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import { useLabelLogic } from './Label.logic';
import { classes } from './Label.st.css';
import { ReactSVG } from 'react-svg';

export const Label = ({ label }: { label: LabelType }) => {
  const { showItemLabelsName, showItemLabelsIcon } = useLabelLogic();

  return (
    <Badge
      className={classes.label}
      data-hook={dataHooks.item.label}
      priority={BadgePriority.light}
      icon={
        showItemLabelsIcon ? (
          <ReactSVG aria-label={label.name} className={classes.icon} src={label.icon?.url ?? ''} />
        ) : undefined
      }
    >
      {showItemLabelsName && label.name}
    </Badge>
  );
};
