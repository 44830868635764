import React from 'react';
import type { FC } from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { Menu } from 'root/components/Menus/Menu/Menu';
import type { PopulatedMenu } from 'root/apiTypes';
import { dataHooks } from 'root/dataHooks';
import type { NavigateToFn } from 'root/clientTypes';
import { useMenusLogic } from './Widget.logic';
import { AppWrapper } from 'root/components/Menus/AppWrapper/AppWrapper';
import { classes } from './Widget.st.css';
import { Navigation } from 'root/components/Menus/Navigation/Navigation';
import { MenuState } from 'root/components/Menus/MenuState/MenuState';

export type ControllerProps = {
  menus: PopulatedMenu[];
  navigateTo: NavigateToFn;
  error?: Error;
};

export const Widget: FC<WidgetProps<ControllerProps>> = ({ menus, navigateTo, error }) => {
  const { activeTab, displayableMenus, shouldShowTabs, setActiveTab } = useMenusLogic({
    menus,
  });

  if (error) {
    return (
      <AppWrapper>
        <MenuState content="app.error-state" />
      </AppWrapper>
    );
  }

  return (
    <AppWrapper>
      {displayableMenus?.length > 0 ? (
        <div className={classes.root} data-hook={dataHooks.menus.container}>
          {shouldShowTabs && (
            <Navigation
              activeItem={activeTab}
              menus={displayableMenus}
              navigateTo={navigateTo}
              setActiveItem={setActiveTab}
            />
          )}
          <Menu menu={displayableMenus[activeTab]} />
        </div>
      ) : (
        <MenuState content="app.empty-state" />
      )}
    </AppWrapper>
  );
};
