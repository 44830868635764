import { useMemo } from 'react';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { Preset } from 'root/utils/presets';
import type { PopulatedMenu } from 'root/apiTypes';
import type { NavigateToFn } from 'root/clientTypes';
import { TabsVariant, type DropdownOptionProps } from 'wix-ui-tpa/cssVars';
import { useGetDesignStylesParams } from 'root/hooks/useGetDesignStylesParams';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { Alignment } from 'root/consts';
import type { StyleParamValue } from 'root/components/Menus/stylesParams';

export const useNavigationLogic = ({
  menus,
  setActiveItem,
  navigateTo,
}: {
  menus: PopulatedMenu[];
  setActiveItem: (index: number) => void;
  navigateTo: NavigateToFn;
}) => {
  const {
    preset,
    gridMenuNavigationLayout,
    columnsMenuNavigationLayout,
    stripMenuNavigationLayout,
    centerMenuNavigationLayout,
    gridMenuNavigationFullWidthTab,
    columnsMenuNavigationFullWidthTab,
    stripMenuNavigationFullWidthTab,
    centerMenuNavigationFullWidthTab,
  } = useGetLayoutStylesParams();
  const { navigationDropdownStyle } = useGetDesignStylesParams();

  const gridMenuNavigationAlignment = useGetAlignment('gridMenuNavigationAlignment');
  const columnsMenuNavigationAlignment = useGetAlignment('columnsMenuNavigationAlignment');
  const stripMenuNavigationAlignment = useGetAlignment('stripMenuNavigationAlignment');

  const alignmentMap: Record<Preset, string> = {
    [Preset.Grid]: gridMenuNavigationAlignment,
    [Preset.Columns]: columnsMenuNavigationAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: stripMenuNavigationAlignment,
  };

  const navigationLayoutMap: Record<Preset, StyleParamValue> = {
    [Preset.Grid]: gridMenuNavigationLayout,
    [Preset.Columns]: columnsMenuNavigationLayout,
    [Preset.Center]: centerMenuNavigationLayout,
    [Preset.Strip]: stripMenuNavigationLayout,
  };

  const navigationFullWidthTabMap: Record<Preset, StyleParamValue> = {
    [Preset.Grid]: gridMenuNavigationFullWidthTab,
    [Preset.Columns]: columnsMenuNavigationFullWidthTab,
    [Preset.Center]: centerMenuNavigationFullWidthTab,
    [Preset.Strip]: stripMenuNavigationFullWidthTab,
  };

  const navigationAlignment = alignmentMap[preset as Preset];
  const navigationLayout = navigationLayoutMap[preset as Preset];
  const navigationFullWidthTab = navigationFullWidthTabMap[preset as Preset];
  const tabsWidth = navigationFullWidthTab ? TabsVariant.fullWidth : TabsVariant.fit;
  const tabOptions = useMemo(
    () => menus.map((menu: PopulatedMenu, index: number) => ({ title: menu?.name, id: index.toString() })),
    [menus]
  );

  const dropdownOptions = useMemo(
    () => tabOptions.map((option) => ({ id: option.id, value: option.title, isSelectable: true })),
    [tabOptions]
  );

  const onClickTab = async (index: number) => {
    const menu = menus[index];
    const menuId = menu.id ?? '';
    const urlQueryParam = menu.urlQueryParam;
    setActiveItem(index);
    await navigateTo({ menuId, urlQueryParam });
  };

  const onClickNavigationDropdownOption = async (option: DropdownOptionProps) => {
    await onClickTab(Number(option.id));
  };

  return {
    navigationLayout,
    tabsWidth,
    dropdownOptions,
    tabOptions,
    navigationAlignmentString: navigationAlignment,
    onClickNavigationDropdownOption,
    onClickTab,
    navigationDropdownStyle,
  };
};
