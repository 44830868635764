import React from 'react';
import type { PopulatedSection } from 'root/apiTypes';
import { classes } from './Sections.st.css';
import { Section } from 'root/components/Menus/Section/Section';

export const Sections = ({ sections }: { sections: PopulatedSection[] }) => {
  return (
    <div className={classes.sectionsContainer}>
      {sections.map((section) => (
        <Section key={section.id} section={section} />
      ))}
    </div>
  );
};
