import { useStyles } from '@wix/tpa-settings/react';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { Preset } from 'root/utils/presets';

export const usePriceVariantsLogic = () => {
  const styles = useStyles();
  const preset = styles.get(stylesParams.preset) as Preset;

  const { showItemVariantsDivider } = useGetDisplayStylesParams();

  const showDivider = preset === Preset.Center && showItemVariantsDivider;

  return { showDivider };
};
