import type { CSSProperties } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { Preset } from 'root/utils/presets';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { Alignment } from 'root/consts';

export const useSectionLogic = () => {
  const { showSectionName, showSectionDescription, showSectionDividers } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();
  const gridSectionAlignment = useGetAlignment('gridSectionAlignment');
  const columnsSectionAlignment = useGetAlignment('columnsSectionAlignment');
  const stripSectionAlignment = useGetAlignment('stripSectionAlignment');

  const shouldShowTopDivider = showSectionDividers && [Preset.Grid, Preset.Center].includes(preset as Preset);
  const shouldShowBottomDivider = showSectionDividers && preset === Preset.Grid;

  const textAlignCssMap: Record<Preset, string> = {
    [Preset.Grid]: gridSectionAlignment,
    [Preset.Columns]: columnsSectionAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: stripSectionAlignment,
  };

  return {
    showSectionName,
    showSectionDescription,
    textAlignCss: { textAlign: textAlignCssMap[preset as Preset] as CSSProperties['textAlign'] },
    shouldShowTopDivider,
    shouldShowBottomDivider,
  };
};
