import React from 'react';
import { useAppWrapperLogic } from './AppWrapper.logic';
import { RtlProvider } from 'root/components/Menus/shared/RtlProvider/RtlProvider';
import { dataHooks } from 'root/dataHooks';

export const AppWrapper = ({ children }: { children: React.ReactNode }) => {
  const { presetName, isMobile } = useAppWrapperLogic();

  return (
    <RtlProvider>
      <div className={`preset ${presetName} ${isMobile ? 'mobile' : ''}`} data-hook={dataHooks.app.container}>
        {children}
      </div>
    </RtlProvider>
  );
};
