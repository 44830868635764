import { useStyles } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { getPresetName } from 'root/utils/presets';

export const useAppWrapperLogic = () => {
  const styles = useStyles();
  const { isMobile } = useEnvironment();

  const preset = styles.get(stylesParams.preset);
  const presetName = getPresetName(preset);

  return { presetName, isMobile };
};
