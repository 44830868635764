import type { PopulatedMenu } from 'root/apiTypes';
import { getMenusMap } from 'root/utils/menus';
import type { MenuOrder } from 'root/components/Menus/Settings/Tabs/MenusTab/MenusSettingsParams';

export const getMenusToDisplay = ({
  menusDisplayOrder,
  menus,
}: {
  menusDisplayOrder: MenuOrder[];
  menus: PopulatedMenu[];
}) => {
  const allMenusMap = getMenusMap(menus);

  const menusToDisplay = menusDisplayOrder.reduce((acc: PopulatedMenu[], menuOrder: MenuOrder) => {
    const menu = allMenusMap[menuOrder.id];
    if (menu) {
      if (menuOrder.checked) {
        acc = acc.concat(menu);
      }
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete allMenusMap[menuOrder.id];
    }
    return acc;
  }, []);

  // Add new menus to the end
  Object.values(allMenusMap).forEach((menu) => {
    menusToDisplay.push(menu);
  });

  return menusToDisplay;
};
