import { useStyles } from '@wix/tpa-settings/react';
import type { IStylesParams } from 'root/components/Menus/stylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';
import { Alignment } from 'root/consts';

export const getAlignmentText = (alignment: Alignment): string => {
  switch (alignment) {
    case Alignment.Center:
      return 'center';
    case Alignment.Right:
      return 'right';
    case Alignment.Left:
    default:
      return 'left';
  }
};

export const useGetAlignment = (style: keyof IStylesParams) => {
  const styles = useStyles();

  const alignment = styles.get(stylesParams[style]);

  return getAlignmentText(alignment as Alignment);
};
