import { useStyles } from '@wix/tpa-settings/react';
import type { ILayoutStylesParams } from 'root/components/Menus/Settings/Tabs/LayoutTab/LayoutStylesParams';
import { LayoutStylesParams } from 'root/components/Menus/Settings/Tabs/LayoutTab/LayoutStylesParams';
import type { StyleParamValue } from 'root/components/Menus/stylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';

export const useGetLayoutStylesParams = () => {
  const styles = useStyles();

  const layoutStylesParams = Object.fromEntries(
    Object.keys(LayoutStylesParams).map((param) => [
      param,
      styles.get(stylesParams[param as keyof ILayoutStylesParams]),
    ])
  ) as Record<keyof ILayoutStylesParams, StyleParamValue>;

  return layoutStylesParams;
};
