import type { CSSProperties } from 'react';
import { useGetDisplayStylesParams } from 'root/hooks/useGetDisplayStylesParams';
import { useGetLayoutStylesParams } from 'root/hooks/useGetLayoutStylesParams';
import { Preset } from 'root/utils/presets';
import { getAlignmentText, useGetAlignment } from 'root/hooks/useGetAlignment';
import { Alignment } from 'root/consts';

export const useMenuLogic = () => {
  const { showMenuName, showMenuDescription } = useGetDisplayStylesParams();
  const { preset } = useGetLayoutStylesParams();

  const gridMenuAlignment = useGetAlignment('gridMenuAlignment');
  const columnsMenuAlignment = useGetAlignment('columnsMenuAlignment');
  const stripMenuAlignment = useGetAlignment('stripMenuAlignment');

  const textAlignCssMap: Record<Preset, string> = {
    [Preset.Grid]: gridMenuAlignment,
    [Preset.Columns]: columnsMenuAlignment,
    [Preset.Center]: getAlignmentText(Alignment.Center),
    [Preset.Strip]: stripMenuAlignment,
  };

  const textAlignCss = { textAlign: textAlignCssMap[preset as Preset] as CSSProperties['textAlign'] };

  return { showMenuName, showMenuDescription, textAlignCss };
};
