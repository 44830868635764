import { useStyles } from '@wix/tpa-settings/react';
import type { IDesignStylesParams } from 'root/components/Menus/Settings/Tabs/DesignTab/DesignStylesParams';
import { DesignStylesParams } from 'root/components/Menus/Settings/Tabs/DesignTab/DesignStylesParams';
import { stylesParams } from 'root/components/Menus/stylesParams';
import type { StyleParamValue } from 'root/components/Menus/stylesParams';

export const useGetDesignStylesParams = () => {
  const styles = useStyles();

  const designStylesParams = Object.fromEntries(
    Object.keys(DesignStylesParams).map((param) => [
      param,
      styles.get(stylesParams[param as keyof IDesignStylesParams]),
    ])
  ) as Record<keyof IDesignStylesParams, StyleParamValue>;

  return {
    styles,
    ...designStylesParams,
  };
};
